import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import { Button, Textarea, TextInput, Image } from "@mantine/core";
import helpImg from "../../data/Bidbanana1.png";
import { useUserAuth } from "../../contexts/UserAuthContext";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../Firebase";
import { showNotification, updateNotification } from "@mantine/notifications";
import { AiOutlineCheck } from "react-icons/ai";

const ContactHelp = () => {
  const { data, user } = useUserAuth();

  const dataForm = useForm({
    initialValues: {
      name: data ? data.name : "loading...",
      organizationName: data ? data.organization_name : "loading...",
      message: "",
    },
  });

  const userForm = useForm({
    initialValues: {
      name: user ? user.displayName : " ",
      email: user ? user.email : " ",
    },
  });

  //populates the forms after data has been loaded
  useEffect(() => {
    if (user && data) {
      dataForm.setValues({
        name: data.name,
        organizationName: data.organization_name,
      });
      userForm.setValues({ email: user.email });
    } else if (user) {
      dataForm.setValues({ name: "", organizationName: "" });
      userForm.setValues({ email: user.email });
    }
  }, [user, data]);

  return (
    <div className="flex justify-center">
      <div className="max-w-3xl m-3 border-1 rounded-xl">
        <form
          onSubmit={dataForm.onSubmit(async (data, values) => {
            try {
              if (
                data.name != "" &&
                user.email != "" &&
                data.organizationName != "" &&
                values.message != ""
              ) {
                showNotification({
                  id: "load-data",
                  title: "Uploading bid info",
                  color: "bb-light-green.0",
                  loading: true,
                });

                const doc = await addDoc(collection(db, "mail"), {
                  to: "maurice@thebidlab.com, amandam@thebidlab.com, jacob@thebidlab.com, ross@thebidlab.com",
                  message: {
                    subject: "Bid Banana: Support Request",
                    html: `${data.name} from ${data.organizationName} has requested Bid Banana Support: ${data.message}. They can be reached at ${user.email}`,
                  },
                });
                if (doc.id == null) {
                  updateNotification({
                    id: "load-data",
                    title: "Mail failed to send",
                    color: "red",
                    icon: <AiOutlineCheck size={16} />,
                    autoClose: 2000,
                  });
                } else {
                  updateNotification({
                    id: "load-data",
                    title:
                      "Thank you! Your message has been sent and we will reach out to you shortly via email!",
                    color: "teal",
                    icon: <AiOutlineCheck size={16} />,
                    autoClose: 2000,
                  });
                }
              } else {
                alert("Field cannot be left empty");
              }
            } catch (e) {
              console.log(e);
            }
          })}
        >
          <section className="m-2 p-2 flex justify-center   rounded-xl ">
            <Image
              src={helpImg}
              position="centered"
              fit="cover"
              height={200}
              alt="Assistance Banana"
              radius="lg"
            />
          </section>
          <section className="m-2 p-2   rounded-xl">
            <article className="px-2 items-center">
              <div className="flex justify-center">
                <h1 className="font-medium">
                  Get Help from Our Knowledgeable Support Staff
                </h1>
              </div>
              <div className="flex justify-center">
                <h2 className="text-justify">
                  When you encounter any questions, concerns, or technical
                  challenges, don't hesitate to reach out to our dedicated
                  support team. We take pride in providing prompt and
                  personalized assistance to address your unique needs.
                </h2>
              </div>
            </article>
          </section>
          <section className="m-2 p-2  rounded-xl">
            <article className="px-2">
              <TextInput
                label="Name"
                placeholder="Name"
                required
                {...dataForm.getInputProps("name")}
              />
              <TextInput
                mt="md"
                label="Email"
                required
                placeholder="Email"
                {...userForm.getInputProps("email")}
              />
              <TextInput
                mt="md"
                mb="md"
                required
                label="Company/Organization Name"
                placeholder="Company/Organization Name"
                {...dataForm.getInputProps("organizationName")}
              />
            </article>

            <article className="px-2">
              <Textarea
                mb="md"
                label="How can we help?"
                required
                placeholder="Message"
                {...dataForm.getInputProps("message")}
              />
            </article>
          </section>
          <section className="m-2 p-1 flex justify-end mt-2">
            <Button
              // onClick={() => setOpened2(false)}
              variant="outline"
              type="submit"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green  border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
            >
              Submit
            </Button>
          </section>
        </form>
      </div>
    </div>
  );
};

export default ContactHelp;
