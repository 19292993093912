import React, { useState, useEffect } from "react";
import { useUserAuth } from "../contexts/UserAuthContext";
import { GlobalLoader, BananaBanner, PleaseLogin } from "../components";
import { NotFound } from ".";
import { Link } from "react-router-dom";
import { Button, Modal, Checkbox, Textarea } from "@mantine/core";
import { db } from "../Firebase";
import { getFirestore, getDocs, collection, doc, setDoc } from "firebase/firestore";
import { HiOutlineExternalLink } from "react-icons/hi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button as PRButton } from "primereact/button";
import ScraperFieldStatuses from "../components/Statuses/ScraperFieldStatuses";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";
import { showNotification, updateNotification } from "@mantine/notifications";

const StatusesV2 = () => {
  useAbleCDPTracking();
  // Access user state
  const AuthContext = useUserAuth();
  const user = AuthContext.user;

  // Initialize Firestore for data updates
  const firestore = getFirestore();

  // State for holding scraper data
  const [statuses, setStatuses] = useState(null);

  // Initialize modal states and functions
  const [detailsModalOpened, setDetailsModalOpened] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState(false);

  const detailsOnClick = (data) => {
    setDetailsModalData(data);
    setDetailsModalOpened(true);
  };

  // Filter states for fields with filtering enabled.
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);
  const [flags] = useState([
    "AI Enhanced",
    "State",
    "City - Major",
    "Federal",
    "Department",
    "Local City / Town / Municipality",
    "County",
    "Transportation Authority / Airports",
    "Utilities",
    "Education – K – 12",
    "Education – University",
    "Consortium",
    "Other",
  ]);
  const [scraperStatuses] = useState(["Success", "Failed", "No bids"]);
  const [overallErrorStatus] = useState(["OK", "ERROR"]);
  const [shard_number] = useState(["1", "2", "3", "4", "5", "6", "7", "dev"]);
  const [priority] = useState(["C", "H", "M", "L"]);

  // Filter intialization and functions
  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      status: { value: null, matchMode: FilterMatchMode.CONTAINS },
      overallErrorStatus: { value: null, matchMode: FilterMatchMode.IN },
      shard_number: { value: null, matchMode: FilterMatchMode.EQUALS },
      flags: { value: null, matchMode: FilterMatchMode.CONTAINS },
      priority: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearFilter = () => {
    initFilters();
  };

  // *** Data Table Render/Filter Templates ***

  // Flag field templates
  const flagRenderTemplate = (scraper_status) => {
    return (
      <p className="min-h-24 border rounded m-auto p-2">{scraper_status.flags}</p>
    );
  };

  const flagItemTemplate = (option) => {
    return <p>{option}</p>;
  };

  const flagFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={flags}
        onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        itemTemplate={flagItemTemplate}
        showClear
        placeholder="Any"
        className="p-column-filter"
      />
    );
  };

  // Status field templates
  const statusRenderTemplate = (scraper_status) => {
    return (
      <div className="flex mr-auto">
        <div className="flex mr-2">
          {(() => {
            if (scraper_status.status === "FAILED") {
              return <Checkbox readOnly indeterminate color="red" size="sm" checked />;
            } else if (scraper_status.status === "NO BIDS") {
              return <Checkbox readOnly indeterminate color="gray" size="sm" checked />;
            } else if (scraper_status.status === "ISSUE") {
              // Handle the "ISSUE" status
              return <Checkbox readOnly color="orange" size="sm" checked />;
            } else {
              return <Checkbox readOnly color="green" size="sm" checked />;
            }
          })()}
        </div>
        {scraper_status.status + (scraper_status.status === "FAILED" && scraper_status.consecutive_failures ? " (" + scraper_status.consecutive_failures + ")" : "")}
      </div>
    );
  };

  const statusItemTemplate = (option) => {
    return <p>{option}</p>;
  };

  // Uptime field templates
  const uptimeRenderTemplate = (scraper_status) => {
    return <p style={{
      textAlign: "center",
      borderRadius: "0.5rem",
      backgroundColor: scraper_status.uptime_color_css || "#D8D8D8",
    }}>
      {Number.isSafeInteger(scraper_status.uptime_percent) ? `${scraper_status.uptime_percent}%` : "N/A"}
    </p>;
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={scraperStatuses}
        onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder="Any"
        className="p-column-filter"
      />
    );
  };

  // Timestamp field templates
  const timestampRenderTemplate = (scraper_status) => {
    return <p>{new Date(scraper_status.timestamp).toLocaleString()}</p>;
  };

  // Data Quality field templates
  const qualityItemTemplate = (option) => {
    return <p>{option}</p>;
  };

  const qualityFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={overallErrorStatus}
        onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        itemTemplate={qualityItemTemplate}
        showClear
        placeholder="Any"
        className="p-column-filter"
      />
    );
  };

  const qualityRenderTemplate = (scraper_status) => {
    return (
      <div className="flex mr-auto">
        <div className="flex mr-2">
          {(() => {
            if (scraper_status?.overallErrorStatus == "ERROR") {
              return <Checkbox readOnly indeterminate color="orange" size="sm" checked />;
            } else {
              return <Checkbox readOnly color="green" size="sm" checked />;
            }
          })()}
        </div>
        {scraper_status?.overallErrorStatus}
      </div>
    );
  };

  // Shard field templates
  const shardRenderTemplate = (scraper_status) => {
    return <p>{scraper_status.shard_number}</p>;
  };

  const shardItemTemplate = (option) => {
    return <p>{option}</p>;
  };

  const shardFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={shard_number}
        onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        itemTemplate={shardItemTemplate}
        showClear
        placeholder="Any"
        className="p-column-filter"
      />
    );
  };

  // URL field templates
  const urlRenderTemplate = (scraper_status) => {
    return (
      <div>
        <a href={scraper_status.site_url} target="_blank" rel="noreferrer" className="hover:cursor-pointer">
          <HiOutlineExternalLink className="text-large text-gray-600" />
        </a>
      </div>
    );
  };

  // Total Bid Count field templates
  const bidCountRenderTemplate = (scraper_status) => {
    return <p>{scraper_status.bidCount}</p>;
  };

  // Priority field templates
  const priorityRenderTemplate = (scraper_status) => {
    return <p className="border rounded m-auto p-2">{scraper_status.priority}</p>;;
  };

  const priorityItemTemplate = (option) => {
    return <p>{option}</p>;
  };

  const priorityFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={priority}
        onChange={(e) => options.filterApplyCallback(e.value, options.index)}
        itemTemplate={priorityItemTemplate}
        showClear
        placeholder="Any"
        className="p-column-filter"
      />
    );
  };

  // Notes field templates
  const notesRenderTemplate = (scraper_status) => {
    return <p className="border rounded m-auto p-2">{scraper_status.notes}</p>;
  };

  // Details field templates
  const detailsButtonRenderTemplate = (scraper_status) => {
    return (
      <Button
        onClick={() => detailsOnClick(scraper_status)}
        variant="outline"
        size="sm"
        radius="xl"
        className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-100 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black tour-getStarted"
      >
        Details
      </Button>
    );
  };

  // Cell edit functions

  // Flag cell
  const onFlagCellEditComplete = async (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;

    // Set the new flags in the site's status document
    const siteDocRef = doc(firestore, "scraper_statuses", rowData.site_name);
    try {
      await setDoc(
        siteDocRef,
        {
          flags: newValue,
        },
        { merge: true }
      );
      showNotification({
        title: `${rowData.site_name} flags updated!`,
        autoClose: 3000,
        color: "green",
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Priority cell
  const onPriorityCellEditComplete = async (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;

    // Set the new flags in the site's status document
    const siteDocRef = doc(firestore, "scraper_statuses", rowData.site_name);
    try {
      await setDoc(
        siteDocRef,
        {
          priority: newValue,
        },
        { merge: true }
      );
      showNotification({
        title: `${rowData.site_name} priority updated!`,
        autoClose: 3000,
        color: "green",
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Notes cell
  const onNotesCellEditComplete = async (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;

    // Set the new flags in the site's status document
    const siteDocRef = doc(firestore, "scraper_statuses", rowData.site_name);
    try {
      await setDoc(
        siteDocRef,
        {
          notes: newValue,
        },
        { merge: true }
      );
      showNotification({
        title: `${rowData.site_name} notes updated!`,
        autoClose: 3000,
        color: "green",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const flagTextEditor = (options) => {
    return <Textarea autosize value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const flagCellEditor = (options) => {
    return flagTextEditor(options);
  };

  const priorityTextEditor = (options) => {
    return <InputText style={{ maxWidth: "4rem" }} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const priorityCellEditor = (options) => {
    return priorityTextEditor(options);
  };

  const notesTextEditor = (options) => {
    return <Textarea autosize value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const notesCellEditor = (options) => {
    return notesTextEditor(options);
  };

  // Header for table containg the gobal keyword search filter
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="mr-2">
          <InputText className="border-2 text-sm" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </span>
        <PRButton className="" type="button" label="Clear" outlined onClick={clearFilter} />
      </div>
    );
  };

  const header = renderHeader();

  // Fetch scraper data from Firebase, set the "statuses" state, and initialize filters.
  useEffect(() => {
    const statusesCollection = collection(db, "scraper_statuses");
    getDocs(statusesCollection).then((statusSnap) => {
      let data = statusSnap.docs.map((doc) => doc.data());
      setStatuses(data);
    });
    initFilters();
  }, []);

  if (user == null) {
    return (
      <div id="Favorites" className="mb-10 h-fit flex-grow flex-col w-full relative justify-center">
        <div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center ">
          <BananaBanner title1="Oops!" title2="Please log in" />
          <section>
            <PleaseLogin text="Search is only available to Bid Banana Users" description="Please log in to access search or create an account!" />
          </section>
          <div id="buttons wrapper" className="flex text-center justify-center sm:mt-12 sm:mb-5 ">
            <Button
              variant="outline"
              type="type"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              component={Link}
              to="/register"
            >
              Sign up to continue
            </Button>
            <div className="inline-block align-baseline pt-2">
              <Link to="/login" className="text-bb-light-green font-semibold mx-8 align-baseline">
                Or sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (user.searchKey == null || !user.admin) {
    return (
      <div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center ">
        <NotFound />
      </div>
    );
  }

  if (statuses != null) {

    // Status Counts
    const greenCheckCount = statuses.filter((status) => status.status !== "FAILED" && status.status !== "NO BIDS").length;
    const failedCount = statuses.filter((status) => status.status === "FAILED").length;
    const orangeCheckCount = statuses.filter((status) => status.overallErrorStatus === "ERROR").length;
    const grayIndeterminateCheckCount = statuses.filter((status) => status.status === "NO BIDS" && !status.flags?.includes("AI Enhanced")).length;
	const yellowAINoBidCheckCount = statuses.filter((status) => status.status === "NO BIDS" && status.flags?.includes("AI Enhanced")).length;

    return (
      <div id="wrapper" className="h-fit flex-grow w-full relative justify-center">
        <header className="flex flex-col justify-center mt-0 items-center min-h-16 pt-0  text-center">
          <p className="m-0">{/* logo here */}</p>
          <BananaBanner title1="Scraper Status Panel" />
        </header>
        <div id="PageBodyContents" className="flex flex-col items-center w-full pt-8 px-2  bg-[#f8f9fa]">
          {/* Details Modal */}
          <Modal
            centered
            overlayColor="gray"
            overlayOpacity={0.55}
            overlayBlur={3}
            size="xl"
            closeOnEscape
            withCloseButton={false}
            opened={detailsModalOpened}
            onClose={() => setDetailsModalOpened(false)}
            onSubmit={() => setDetailsModalOpened(true)}
          >
            <h2 className="font-bold">Error:</h2>
            {detailsModalData.error_stacktrace ? <p className="m-auto py-2 pr-5">{detailsModalData.error_stacktrace}</p> : <p className="m-auto py-4 pr-5">No error at this time</p>}
            <h2 className="font-bold py-2">Data Quality:</h2>
            <ScraperFieldStatuses status={detailsModalData} />
          </Modal>

          {/* At a glance Status tally */}
          <div className="border-1 border-gray-200 bg-[#fff]  rounded-md px-2 sm:px-6 py-2 sm:py-3 w-1/2 sm:w-3/8 lg:w-1/4 mb-5 overflow-x-auto">
            <div className="flex">
              <Checkbox readOnly color="green" size="md" checked className="pr-2" />
              <div className="pr-1">
                <strong>{greenCheckCount}</strong> scrapers ran successfully
              </div>
            </div>
			<div className="flex">
              <Checkbox readOnly color="yellow" size="md" checked className="pr-2" />
              <div className="pr-1">
                <strong>{yellowAINoBidCheckCount}</strong> AI scrapers ran sucessfully and found no new bids
              </div>
            </div>
            <div className="flex">
              <Checkbox readOnly indeterminate color="red" size="md" checked className="pr-2" />
              <div className="pr-1">
                <strong>{failedCount}</strong> scrapers failed during execution
              </div>
            </div>
            <div className="flex">
              <Checkbox indeterminate readOnly color="orange" size="md" checked className="pr-2" />
              <div className="pr-1">
                <strong>{orangeCheckCount}</strong> scrapers have issues in data fields
              </div>
            </div>
            <div className="flex">
              <Checkbox readOnly indeterminate color="gray" size="md" checked className="pr-2" />
              <div className="pr-1">
                <strong>{grayIndeterminateCheckCount}</strong> scrapers ran and had no bids
              </div>
            </div>
          </div>
          <div id="StatusContentWrapper" className="border-1 border-gray-200 bg-[#fff]  rounded-md px-2 sm:px-6 py-2 sm:py-3  w-full sm:w-3/4 lg:w-full mb-10 overflow-x-auto">
            <DataTable
              value={statuses}
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink  RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} scrapers"
              rows={100}
              rowsPerPageOptions={[100, 250, 500]}
              removableSort
              className="font-Barlow"
              size="sm"
              editMode="cell"
              scrollable
              stripedRows
              filters={filters}
              header={header}
              globalFilterFields={["site_name", "status", "shard_number", "overallErrorStatus", "flags", "priority"]}
              emptyMessage="No scrapers found."
            >
              <Column field="site_name" header="Site Name" sortable style={{ minWidth: "10rem" }}></Column>
              <Column
                header="Flags"
                field="flags"
                style={{ minWidth: "10rem", maxWidth: "16rem" }}
                editor={(options) => flagCellEditor(options)}
                onCellEditComplete={onFlagCellEditComplete}
                body={flagRenderTemplate}
                showFilterMatchModes={false}
                filter
                filterElement={flagFilterTemplate}
                filterField="flags"
              ></Column>
              <Column header="Status" body={statusRenderTemplate} showFilterMatchModes={false} filter filterElement={statusFilterTemplate} filterField="status"></Column>
              <Column header="Uptime" body={uptimeRenderTemplate}></Column>
              <Column header="URL" body={urlRenderTemplate}></Column>
              <Column header="Last Ran" body={timestampRenderTemplate}></Column>
              <Column header="Data Quality" body={qualityRenderTemplate} showFilterMatchModes={false} filter filterElement={qualityFilterTemplate} filterField="overallErrorStatus"></Column>
              <Column header="Shard" body={shardRenderTemplate} showFilterMatchModes={false} filter filterElement={shardFilterTemplate} filterField="shard_number"></Column>
              <Column header="Total Bids" body={bidCountRenderTemplate}></Column>
              <Column
                header="Priority"
                field="priority"
                style={{ minWidth: "6rem", maxWidth: "6rem" }}
                editor={(options) => priorityCellEditor(options)}
                onCellEditComplete={onPriorityCellEditComplete}
                body={priorityRenderTemplate}
                showFilterMatchModes={false}
                filter
                filterElement={priorityFilterTemplate}
                filterField="priority"
              ></Column>
              <Column
                header="Notes"
                field="notes"
                style={{ minWidth: "10rem", maxWidth: "16rem" }}
                editor={(options) => notesCellEditor(options)}
                onCellEditComplete={onNotesCellEditComplete}
                body={notesRenderTemplate}
              ></Column>
              <Column body={detailsButtonRenderTemplate} header=""></Column>
            </DataTable>
          </div>
        </div>
      </div>
    );
  } else {
    return <GlobalLoader />;
  }
};

export default StatusesV2;
