import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import { Button, Space, TextInput, Image, Select } from "@mantine/core";
import Bidlabgroup from "../../data/bidlabgroup.png";
import { useUserAuth } from "../../contexts/UserAuthContext";
import { showNotification, updateNotification } from "@mantine/notifications";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../Firebase";
import { HiOutlineX } from "react-icons/hi";
import { AiOutlineCheck } from "react-icons/ai";

const KickoffForm = (props) => {
  const { data, user } = useUserAuth();

  const dataForm = useForm({
    initialValues: {
      name: data ? data.name : "loading...",
      organizationName: data ? data.organization_name : "loading...",
      phoneNumber: data ? data.phone_number : "loading...",
      rfpName: props ? props.title : "",
      url: props ? props.url : "",
      request_type: "",
    },
  });

  const userForm = useForm({
    initialValues: {
      name: user ? user.displayName : " ",
      email: user ? user.email : " ",
      title: props.title,
      url: props.url,
    },
  });

  // Populates the forms after data has been loaded
  useEffect(() => {
    if (user && data) {
      dataForm.setValues({
        name: data.name,
        organizationName: data.organization_name,
        phoneNumber: data.phone_number,
      });
      userForm.setValues({
        email: user.email,
        title: props.title,
        url: props.url,
      });
    } else if (user) {
      dataForm.setValues({ name: "", organizationName: "", phoneNumber: "" });
      userForm.setValues({
        email: user.email,
        title: props.title,
        url: props.url,
      });
    }
  }, [user, data]);

  return (
    <div className="m-x-xs m-3">
      <form
        id="kickoff-form"
        onSubmit={dataForm.onSubmit(async (values) => {
          try {
            if (
              values.name != "" &&
              user.email != "" &&
              values.organizationName != "" &&
              values.phoneNumber != "" &&
              props.title != "" &&
              props.url != "" &&
              values.request_type != ""
            ) {
              if (values.request_type == "Request Bid Documents") {
                showNotification({
                  id: "get-started-transaction",
                  title: "Processing request...",
                  color: "blue",
                  loading: true,
                });

                try {
                  const doc = await addDoc(collection(db, "mail"), {
                    to: "maurice@thebidlab.com, amandam@thebidlab.com, jacob@thebidlab.com, ross@thebidlab.com",
                    message: {
                      subject: "Bid Banana: Request for Bid Documentation",
                      html: `${values.name} from ${values.organizationName} would like to request documentation for an opportunity (${props.title}, ${props.url}). 
                      They can be reached at ${user.email} and by phone at ${values.phoneNumber}`,
                    },
                  });

                  updateNotification({
                    id: "get-started-transaction",
                    title:
                      "Success! Your request has been sent and we will get back to you via email!",
                    color: "green",
                    icon: <AiOutlineCheck size={16} />,
                    autoClose: 7500,
                  });

                } catch (e) {
                  console.log(e);
                  updateNotification({
                    id: "get-started-transaction",
                    title:
                      "An error has occurred submitting your request. Please try again later or contact help if the issue persists.",
                    color: "red",
                    icon: <HiOutlineX size={16} />,
                    autoClose: 7500,
                  });

                }

                // Close modal
                props.handleClose();

              } else {
                try {
                  const doc = await addDoc(collection(db, "mail"), {
                    to: "maurice@thebidlab.com, amandam@thebidlab.com, jacob@thebidlab.com, ross@thebidlab.com",
                    message: {
                      subject: "Bid Banana: Request for Bid Assistance",
                      html: `${values.name} from ${values.organizationName} would like to request bid assistance for an opportunity (${props.title}, ${props.url}). 
                      They can be reached at ${user.email} and by phone at ${values.phoneNumber}`,
                    },
                  });
                } catch (e) {
                  console.log(e);
                }
                props.handleNextStep();
              }
            } else {
              showNotification({
                title: "A required field has been left empty.",
                autoClose: 5000,
                color: "red",
              });
            }
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <section className="m-2 p-2 flex justify-center  border-1 rounded-xl bg-bb-yellow">
          <Image
            src={Bidlabgroup}
            position="centered"
            fit="contain"
            width={200}
            height={200}
          />
        </section>
        <section className="m-2 p-2  ">
          <article className="px-2">
            <div className="font-semibold text-xl font-Lexend-Deca-Regular">
              Ready to get started?
            </div>
            <Space h="sm" />
            <div className="text-justify font-Lexend-Deca-Regular">
              Interested in kicking off this bid or learning more about it?
              Please confirm the following fields and a member of our team will
              contact you shortly. Thanks for your interest and we look forward
              to working with you.
            </div>
            <Space h="md" />
          </article>
          <article className="px-2">
            <TextInput
              label="Name"
              placeholder="Name"
              required
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label:
                  "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required:
                  "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...dataForm.getInputProps("name")}
            />
            <TextInput
              mt="md"
              label="Email"
              required
              placeholder="Email"
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label:
                  "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required:
                  "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...userForm.getInputProps("email")}
            />
            <TextInput
              mt="md"
              mb="md"
              required
              label="Company/Organization Name"
              placeholder="Company/Organization Name"
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label:
                  "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required:
                  "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...dataForm.getInputProps("organizationName")}
            />
            <TextInput
              mt="md"
              mb="md"
              required
              label="Phone number"
              placeholder="Phone number"
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label:
                  "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required:
                  "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...dataForm.getInputProps("phoneNumber")}
            />
          </article>
          <article className="px-2">
            <TextInput
              mt="sm"
              mb="md"
              label="RFP Name"
              disabled
              placeholder="RFP Name"
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label:
                  "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required:
                  "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...userForm.getInputProps("title")}
            />
            <TextInput
              mt="sm"
              mb="md"
              label="URL"
              disabled
              placeholder="URL"
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label:
                  "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required:
                  "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...userForm.getInputProps("url")}
            />
            <Select
              label="Service Type"
              placeholder=""
              data={["Request Bid Documents", "Request Bid Assistance"]}
              required
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label:
                  "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required:
                  "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...dataForm.getInputProps("request_type")}
            />
          </article>
        </section>
        <div className="flex">
          <section className="ml-auto m-2 p-1 flex justify-end mb-3">
            <Button
              // onClick={() => (props.handleNextStep)}
              variant="default"
              size="sm"
              type="submit"
              className="border-1 border-[#425b76] text-[#425b76] font-Lexend-Deca-Regular"
            >
              Next
            </Button>
          </section>
        </div>
      </form>
    </div>
  );
};

export default KickoffForm;
